import { GroundResponse } from '@/api/axios';
import axios from 'axios';
import { getCookie } from 'cookies-next';
import useSWR from 'swr';

export type NotificationInfo = {
  id: number;
  reservationId: number;
  reservationNumber: string;
  productName: string;
  productOptionName: string;
  isRead: boolean;
  type: string;
  createdAt: string;
  isReviewed: boolean;
};

export type NofificationListResponse = {
  content: NotificationInfo[];
};

type FetcherParams = [string, string];

const fetcher = async <T>([url, accessToken]: FetcherParams) => {
  const { data } = await axios.get<GroundResponse<T>>(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return data;
};

export const useUnreadNotification = () => {
  const accessToken = getCookie('token');
  const swr = useSWR<GroundResponse<boolean>>(
    !!accessToken
      ? [`${process.env.NEXT_PUBLIC_APP_API}/app/notifications/unread`, accessToken]
      : null,
    fetcher<boolean>,
    { revalidateOnMount: true }
  );
  return swr;
};

export const useNotificationList = () => {
  const accessToken = getCookie('token');
  const swr = useSWR<GroundResponse<NofificationListResponse>>(
    !!accessToken ? [`${process.env.NEXT_PUBLIC_APP_API}/app/notifications`, accessToken] : null,
    fetcher<NofificationListResponse>,
    {}
  );
  return swr;
};

export const readNotifications = async (ids: number[]) => {
  const accessToken = getCookie('token');
  await axios.patch(
    `${process.env.NEXT_PUBLIC_APP_API}/app/notifications`,
    { ids },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};
